var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-ancillary-other-select-pax",
      "header-bg-variant": "light-info",
      "title": _vm.$t('reservation.addOtherServices'),
      "title-class": "text-airline fw-700 font-medium-4 m-50",
      "header-class": "p-50",
      "body-class": "p-1",
      "footer-class": "p-50",
      "no-close-on-backdrop": "",
      "centered": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showThisModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary mr-1",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-2 d-flex-center",
          attrs: {
            "variant": "info",
            "pill": "",
            "disabled": _vm.loadingNextButton
          },
          on: {
            "click": _vm.openModalOtherSecondHandle
          }
        }, [_c('div', {
          staticClass: "d-flex-center ml-25"
        }, [_vm.loadingNextButton ? _c('BSpinner', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }) : _c('span', [_vm._v("Tiếp tục")])], 1)])], 1)];
      }
    }])
  }, [_c('div', [_c('p', {
    staticClass: "fw-700 text-medium-1"
  }, [_vm._v(" Chọn hành khách để mua thêm gói dịch vụ cho từng hành trình: ")]), _vm._l(_vm.dataList, function (_ref2, index) {
    var segment = _ref2.segment,
      arrPax = _ref2.arrPax,
      addedAncillaryTrip = _ref2.addedAncillaryTrip;
    return _c('BCard', {
      key: index,
      staticClass: "border-warning",
      attrs: {
        "header-bg-variant": "light-warning",
        "header-class": "fw-700 p-50 px-lg-1 mb-50",
        "body-class": "px-50 px-sm-1"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "mr-25"
          }, [_c('span', {
            staticClass: "pr-25 text-body"
          }, [_vm._v(" " + _vm._s("H\xE0nh tr\xECnh: ".concat(segment.departure.iataCode, "-").concat(segment.arrival.iataCode)) + " ")]), _c('span', {
            staticClass: "text-airline"
          }, [_vm._v(" ( " + _vm._s("".concat(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).dayAndMonth)) + " ) ")])]), _c('div', {
            staticClass: "my-25 mr-sm-2"
          }, [_c('b-form-checkbox', {
            staticClass: "text-airline fw-700 font-medium-1",
            attrs: {
              "id": "trip-select-".concat(index + 1),
              "disabled": _vm.disableSelectedAll(arrPax, addedAncillaryTrip),
              "checked": _vm.isCheckedAll(arrPax, addedAncillaryTrip)
            },
            on: {
              "change": function change($event) {
                return _vm.toggleSelectAll(arrPax, addedAncillaryTrip);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.isCheckedAll(arrPax, addedAncillaryTrip) ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('div', _vm._l(_vm.dataPaxList, function (pax, indexPax) {
      return _c('div', {
        key: indexPax,
        staticClass: "d-flex my-50 my-lg-75"
      }, [_c('b-form-checkbox', {
        attrs: {
          "id": "pax-select-".concat(index + 1, "_").concat(indexPax + 1),
          "checked": _vm.isCheckItem(arrPax, pax),
          "disabled": _vm.disableSelectItem(arrPax, pax, addedAncillaryTrip)
        },
        on: {
          "change": function change($event) {
            return _vm.handleChooseItem(arrPax, pax);
          }
        }
      }, [_c('span', {
        staticClass: "fw-700 pl-md-1"
      }, [_vm._v(_vm._s(pax.lastName) + " " + _vm._s(pax.firstName))]), addedAncillaryTrip.some(function (i) {
        return i.paxId === pax.paxId;
      }) ? _c('BBadge', {
        staticClass: "mx-25 px-75 rounded-lg",
        attrs: {
          "variant": "warning"
        }
      }, [_vm._v(" " + _vm._s(['TH'].includes(_vm.bookingSource) ? 'Đã có dịch vụ' : 'Đã có gói') + " ")]) : _vm._e()], 1)], 1);
    }), 0)]);
  })], 2), _c('ModalOtherSecondHandle', {
    attrs: {
      "data-list-to-second": _vm.dataListToSecond
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }